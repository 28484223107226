<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card
          border-variant="primary"
          header="Lista de ventas farmacia"
          header-bg-variant="primary"
          header-text-variant="white"
        >
          <b-card-text>
            <div class="row mb-5">
              <div class="col-md-4">
                <label class="typo__label">Desde</label>
                <b-input type="date" v-model="form.from"></b-input>
              </div>
              <div class="col-md-4">
                <label class="typo__label">Hasta</label>
                <b-input type="date" v-model="form.to"></b-input>
              </div>
              <div class="col-md-4">
                <b-button variant="success" @click="getDrugSales" class="mt-8"
                  >Buscar
                </b-button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <b-table
                  :items="drugs"
                  :fields="fields"
                  head-variant="light"
                  caption-top
                >
                  <template #cell(actions)="data">
                    <b-button
                      size="sm"
                      class="mb-2"
                      variant="primary"
                      v-b-tooltip.hover
                      :title="JSON.stringify(data.item)"
                    >
                      <b-icon icon="info" aria-hidden="true"></b-icon>
                    </b-button>
                  </template>
                </b-table>
              </div>
            </div>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      form: { quantity: 1, drug: "", name: "" },
      drugs: [],
      fields: [
        { key: "created_at", label: "Fecha de venta" },
        { key: "product_name", label: "Medicamento" },
        { key: "patient_name", label: "Comprador" },
        { key: "quantity", label: "Cantidad" },
        { key: "total", label: "Total" }
      ]
    };
  },
  methods: {
    addStock() {
      ApiService.post("drug/stock/update/", this.form).then(() => {
        this.getDrugs();
      });
    },
    launchDrugModal(item) {
      console.log(item);
      this.form.name = item.name;
      this.form.drug = item.id;
    },
    getDrugSales() {
      ApiService.get(
        "invoice-details",
        `?from=${this.form.from}&to=${this.form.to}`
      ).then(res => {
        this.drugs = res.data;
      });
    },

    makeToast(variant = null) {
      this.$bvToast.toast("Éxito al guardar", {
        title: `Todo bien`,
        variant: variant,
        solid: true
      });
    },
    getCurrentDateTimeFormat() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      let formatted = yyyy + "-" + mm + "-" + dd;
      console.log(formatted);
      this.form.from = formatted;
      this.form.to = formatted;
    }
  },
  mounted() {
    this.getCurrentDateTimeFormat();
    this.getDrugSales();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Farmacia" },
      { title: "Venta de medicamentos" }
    ]);
  }
};
</script>

<style scoped>
.card-header {
  padding: 1rem 2.25rem;
}
</style>
